import { IAction } from 'helpers/interfaces';
import { sort, dataFormatterUtils } from 'utils';
import { PlayerReportTypes } from 'redux/actions/backoffice/player-report-actions';

export interface IPlayerData {
    gameId: number;
    startId: number;
    gameSessionId: number;
    totalBet: number;
    totalWin: number;
    net?: number;
    balance: number;
    playerId: number;
    startBalance?: number;
    endBalance?: number;
    currencyId?: number;
    callback?: Function;
    roundId: string;
    gameType: string;
}

export interface IPromotionData {
    bet: number;
    expire: Date;
    gameId: number;
    grantedFreeRounds: number;
    promotionId: string;
    remainedFreeRounds: number;
    win: number;
}

export interface IPlayer {
    playerData?: IPlayerData[];
    sortedPlayerData: IPlayerData[];
    playerInternalId: string | null;
    currencyId?: number | null;
    promotionData?: IPromotionData;
    campaignsData?: any;
    playerDoesntExist: boolean;
}

export const playerInitialState: IPlayer = {
    sortedPlayerData: [],
    currencyId: null,
    campaignsData: null,
    playerInternalId: null,
    playerDoesntExist: false,
};

const playerReportReducer = (state = playerInitialState, actions: IAction) => {
    switch (actions.type) {
        case PlayerReportTypes.PLAYER_REPORT_REQUEST ||
            PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_REQUEST: {
            return playerInitialState;
        }

        case PlayerReportTypes.PLAYER_REPORT_SUCCESS: {
            const { content, extraInfo } = actions.payload;
            const { playerCurrencyId, internalPlayerId } = extraInfo;
            const supplementedContent = content.map((item: IPlayerData) => {
                return { ...item, currencyId: playerCurrencyId };
            });

            return {
                playerData: supplementedContent,
                currencyId: playerCurrencyId,
                internalPlayerId,
                sortedPlayerData: [],
                playerDoesntExist: false,
            };
        }

        case PlayerReportTypes.PLAYER_REPORT_FAILED: {
            const { code } = actions.payload.response.data.error;

            return { ...state, playerDoesntExist: code === 5 };
        }

        case PlayerReportTypes.PLAYER_REPORT_SORT: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedPlayerData: sortState
                    ? sort(
                          actions.payload,
                          dataFormatterUtils.getFormattedData(state.playerData, false),
                      )
                    : [],
            };
        }

        case PlayerReportTypes.PROMOTION_DATA_SUCCESS: {
            return {
                ...state,
                promotionData: actions.payload,
            };
        }

        case PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_SUCCESS: {
            return {
                ...state,
                campaignsData: actions.payload,
            };
        }

        default:
            return state;
    }
};

export default playerReportReducer;
