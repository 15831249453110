import { localStorageHelper } from "utils";

const currenciesMap = new Map();

export const currencyNameConverter = {
    idToCode: (id: string) => {
        if (currenciesMap.size === 0) {
            currencyNameConverter.setCurrencies(localStorageHelper.getChainedValue('user.allCurrencies'));
        }
        return currenciesMap.get(id);
    },

    setCurrencies: (currencies: any) => {
        currencies.forEach((currency: any) => {
            currenciesMap.set(currency.id, currency.code);
        });
    }
};
