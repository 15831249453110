import * as React from 'react';
import './SearchResultStyles.scss';
import PlayerCard from './PlayerCard';
import { ISearchPlayersData } from 'helpers/interfaces';

interface IProps {
    player: ISearchPlayersData;
}

class SearchResult extends React.Component<IProps> {
    render = () => {
        const { player } = this.props;

        return (
            <div className="search-result__wrapper">
                <PlayerCard key={player.login} playerData={player} />
            </div>
        );
    };
}

export default SearchResult;
