import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { searchPlayersSuccess, SearchTypes } from '../../actions/backoffice/search-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const searchEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(SearchTypes.SEARCH_PLAYERS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(`${SERVER_URL}/player/by-login?login=${action.payload}`, {
                    headers,
                }),
            ).pipe(
                map((response) =>
                    searchPlayersSuccess({
                        response: response.data,
                    }),
                ),
                catchError((error) => of(apiError(error, SearchTypes.SEARCH_PLAYERS_REQUEST))),
            );
        }),
    );
