import i18next from 'i18next';

interface IError {
    code: number;
    message: string;
}

interface IErrorResponse {
    error: IError;
    data?: {
        error: IError;
    };
}

interface IPayload {
    response: IErrorResponse | string; // Allow for the possibility that `response` is a string
    xhr: any;
    message: string;
}

export const errorIds: { [code: number]: string } = {
    0: 'general_error',
    1: 'user_not_found',
    2: 'user_is_blocked',
    3: 'session_has_expired',
    4: 'invalid_credentials',
    5: 'invalid_parameters',
    6: 'service_communication_error',
    7: 'access_denied',
    8: 'custom_error',
    9: 'password_change_required',
    1040: 'players_exceed_limit'
};
export const CUSTOM_MESSAGE_CODE = 8;
export const DEFAULT_ERROR_ID = 'server_error';

export const errorHandler = {
    getErrorText: (payload: IPayload): string => {
        let { response } = payload;
        let errorId: string;
        let errorText: string = i18next.t(DEFAULT_ERROR_ID);

        if (typeof response === 'string') {
            response = JSON.parse(response) as IErrorResponse;
        }

        // Check if there's an additional data layer and set error object accordingly
        const errorObject = response.error || (response.data && response.data.error) || null;

        if (errorObject && errorObject.code >= 0) {
            if (errorObject.code === CUSTOM_MESSAGE_CODE) {
                errorText = errorObject.message;
            } else {
                errorId = errorIds[errorObject.code];
                errorText = i18next.t(errorId) || errorId;
            }
        }

        return errorText;
    },
};
