import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import {
    Button,
    Checkbox,
    Divider,
    Empty,
    message,
    Modal,
    Radio,
    Space,
    Table,
    Tooltip,
} from 'antd';
import './UsersComponent.scss';
import {
    blockUser,
    changeNameByAdmin,
    changePasswordByAdmin,
    changeUserRole,
    createUser,
    getUsers,
    unblockUser,
} from 'redux/actions/backoffice/casino-admin-actions';
import { AddUserPopupComponent } from 'Containers/Users/AddUserPopupComponent';
import { ChangeUserStatusLoader } from 'Components/Loader/ChangeUserStatusLoader';
import UserActionsComponent from 'Containers/Users/UserActionsComponent';
import { ChangeUserRolePopupComponent } from 'Containers/Users/ChangeUserRolePopupComponent';
import { CONSTANTS } from 'utils/config';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { Loader } from 'Components/Loader/Loader';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';
import { ChangeUserPasswordPopupComponent } from 'Containers/Users/ChangeUserPasswordPopupComponent';
import { SyncOutlined, UserAddOutlined } from '@ant-design/icons/lib';
import { ChangeUserNamePopupComponent } from 'Containers/Users/ChangeUserNamePopupComponent';
import {
    createdUsers,
    isNameChangedByAdmin,
    isPasswordChangedByAdmin,
    isUserBlocked as isUserBlockedData,
    isUserCreated as isUserCreatedData,
    isUserRoleChanged as isUserRoleChangedData,
    isUserUnblocked as isUserUnblockedData,
} from 'redux/selectors/backoffice/casino-admin-selectors';
import { getServerError } from 'redux/selectors/backoffice/error-selectors';
import { UserComponentColumns } from './UserComponentColumns';
import { SettingOutlined } from '@ant-design/icons';

export interface IUser {
    name: string;
    email: string;
    role: string;
    blocked: boolean;
    onClick?: Function;
    callbacks?: any;
    bounds?: any;
    disabled?: any;
}

interface IState {
    isAddUserPopupVisible: boolean;
    isEditUserPopupVisible: boolean;
    isChangeNamePopupVisible: boolean;
    isChangeRolePopupVisible: boolean;
    isChangePasswordPopupVisible: boolean;

    creating: boolean;
    blocking: boolean;
    unblocking: boolean;
    editingRole: boolean;
    editingName: boolean;
    editingPassword: boolean;

    editingUser: IUser | null;
}

const defaultState: IState = {
    isAddUserPopupVisible: false,
    isEditUserPopupVisible: false,
    isChangeNamePopupVisible: false,
    isChangeRolePopupVisible: false,
    isChangePasswordPopupVisible: false,

    creating: false,
    blocking: false,
    unblocking: false,
    editingRole: false,
    editingName: false,
    editingPassword: false,
    editingUser: null,
};

const UsersComponent: React.FC = () => {
    const [state, setState] = useState<IState>(defaultState);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const users = useSelector(createdUsers);
    const isUserCreated = useSelector(isUserCreatedData);
    const isUserBlocked = useSelector(isUserBlockedData);
    const isUserUnblocked = useSelector(isUserUnblockedData);
    const isUserRoleChanged = useSelector(isUserRoleChangedData);
    const isNameChanged = useSelector(isNameChangedByAdmin);
    const isPasswordChanged = useSelector(isPasswordChangedByAdmin);
    const serverError = useSelector(getServerError);
    const columns = UserComponentColumns();
    const [tableSize, setTableSize] = useState<'small' | 'middle' | 'large'>('middle');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [checkedList, setCheckedList] = useState(columns.map((item: any) => item.key));
    const visibleColumns = columns.filter((column: any) => checkedList.includes(column.key));
    const totalWidth = visibleColumns.reduce((total: any, col: any) => total + (col.width || 0), 0);
    const options = columns.map(({ key, label }) => ({
        label: t(label),
        value: key,
    }));

    const calculateTableHeight = useCallback(() => {
        const height = window.innerHeight * 0.8 - 194;
        setTableHeight(height);
    }, []);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, [calculateTableHeight]);

    const showMessage = (text: string) => message.success(text, CONSTANTS.message_duration);
    const showError = (text: string) => message.error(text, CONSTANTS.message_duration);

    useEffect(() => {
        const { creating, blocking, unblocking, editingRole, editingName, editingPassword } = state;

        message.destroy();

        if (creating && isUserCreated !== null) {
            isUserCreated
                ? showMessage(t('create_user_success'))
                : showError(t('create_user_failed'));
        } else if (blocking && isUserBlocked !== null) {
            isUserBlocked
                ? showMessage(t('block_user_success'))
                : showError(t('block_user_failed'));
        } else if (unblocking && isUserUnblocked !== null) {
            isUserUnblocked
                ? showMessage(t('unblock_user_success'))
                : showError(t('unblock_user_failed'));
        } else if (editingRole && isUserRoleChanged !== null) {
            isUserRoleChanged
                ? showMessage(t('change_user_role_success'))
                : showError(t('change_user_role_failed'));
        } else if (editingPassword && isPasswordChanged !== null) {
            isPasswordChanged
                ? showMessage(t('password_was_changed_successfully'))
                : showError(t('change_password_failed'));
        } else if (editingName && isNameChanged !== null) {
            isNameChanged
                ? showMessage(t('user_name_was_changed'))
                : showError(t('user_name_was_not_changed'));
        } else {
            return;
        }

        setState(defaultState);
    }, [
        state,
        isUserCreated,
        isUserBlocked,
        isUserUnblocked,
        isUserRoleChanged,
        isNameChanged,
        isPasswordChanged,
        t,
    ]);

    const onAddUserClicked = () => setState({ ...state, isAddUserPopupVisible: true });

    const handleAddUserCancel = () => setState({ ...state, isAddUserPopupVisible: false });

    const handleAddUserSubmit = (values: any) => {
        const { email, password, name, role, currency, timezone } = values;

        dispatch(
            createUser({
                email: email.trim(),
                password: password.trim(),
                name: name.trim(),
                role: role.toUpperCase(),
                userId: 1,
                preferences: { timeZone: timezone, currency },
            }),
        );

        setState({ ...state, isAddUserPopupVisible: false, creating: true });
    };

    const handleChangeUserRoleCancel = () =>
        setState({ ...state, isChangeRolePopupVisible: false });

    const handleChangeUserRoleSubmit = (values: any) => {
        const { editingUser } = state;
        const email = editingUser && editingUser.email;

        dispatch(changeUserRole({ role: values.role.toUpperCase(), email }));
        setState({ ...state, isChangeRolePopupVisible: false, editingRole: true });
    };

    const handleChangeUserNameCancel = () =>
        setState({ ...state, isChangeNamePopupVisible: false });

    const handleChangeUserNameSubmit = (values: any) => {
        const { editingUser } = state;
        const email = editingUser && editingUser.email;

        dispatch(changeNameByAdmin({ email, name: values.name }));
        setState({ ...state, isChangeNamePopupVisible: false, editingName: true });
    };

    const handleChangePasswordCancel = () =>
        setState({ ...state, isChangePasswordPopupVisible: false });

    const handleChangePasswordSubmit = (values: any) => {
        const { editingUser } = state;
        const email = editingUser && editingUser.email;

        dispatch(changePasswordByAdmin({ email, password: values.password }));
        setState({ ...state, isChangePasswordPopupVisible: false, editingPassword: true });
    };

    const handleUserBlock = (user: IUser) => {
        if (user) {
            dispatch(blockUser({ email: user.email }));
            setState({ ...state, isEditUserPopupVisible: false, blocking: true });
        }
    };

    const handleUserUnblock = (user: IUser) => {
        if (user) {
            dispatch(unblockUser({ email: user.email }));
            setState({ ...state, isEditUserPopupVisible: false, unblocking: true });
        }
    };

    const isUserStatusChanging = () => {
        const { creating, blocking, unblocking, editingRole, editingPassword } = state;

        return creating || blocking || unblocking || editingRole || editingPassword;
    };

    const handlePageSizeChange = (current: any, size: any) => {
        setPageSize(size);
    };

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    const getHeader = () => {
        const enabled = users && users.length > 0;

        return (
            <HeaderTitleComponent
                title={t('users')}
                customBreadcrumbs={<div>{t('all')}</div>}
                content={
                    <Space.Compact className="users-header-buttons" style={{ marginTop: '8px' }}>
                        <Button disabled={!enabled} onClick={() => dispatch(getUsers())}>
                            <Tooltip title={t('refresh')}>
                                <SyncOutlined />
                            </Tooltip>
                        </Button>
                        <Button
                            danger
                            disabled={!enabled}
                            onClick={() => enabled && onAddUserClicked()}
                        >
                            <UserAddOutlined />
                            {t('add_user')}
                        </Button>
                    </Space.Compact>
                }
            />
        );
    };

    const usersWithActions = users
        ? users.map((user: IUser) => ({
              ...user,
              actions: (
                  <UserActionsComponent
                      user={user}
                      onChangeName={() =>
                          setState({ ...state, editingUser: user, isChangeNamePopupVisible: true })
                      }
                      onChangeRole={() =>
                          setState({ ...state, editingUser: user, isChangeRolePopupVisible: true })
                      }
                      onChangePassword={() =>
                          setState({
                              ...state,
                              editingUser: user,
                              isChangePasswordPopupVisible: true,
                          })
                      }
                      onBlockUser={() => handleUserBlock(user)}
                      onUnblockUser={() => handleUserUnblock(user)}
                  />
              ),
          }))
        : null;

    return (
        <>
            <ContentComponent
                customBreadcrumbs={<div>{t('all')}</div>}
                header={getHeader()}
                innerContent={
                    serverError ? (
                        <ErrorComponent error={serverError} />
                    ) : users ? (
                        users.length ? (
                            <div className="casino_performance-table-container">
                                <div className={'casino_performance-table-title'}>
                                    <Button
                                        style={{ marginRight: '8px', zIndex: '10' }}
                                        onClick={toggleModal}
                                    >
                                        <SettingOutlined />
                                    </Button>
                                    <Modal
                                        title="Table tools"
                                        open={isModalVisible}
                                        onOk={toggleModal}
                                        onCancel={toggleModal}
                                        footer={[
                                            <Button
                                                key="submit"
                                                type="primary"
                                                onClick={toggleModal}
                                            >
                                                OK
                                            </Button>,
                                        ]}
                                    >
                                        <Divider>Columns displayed</Divider>
                                        <Checkbox.Group
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                marginBottom: '20px',
                                            }}
                                            options={options}
                                            value={checkedList}
                                            onChange={(checkedValues) => {
                                                setCheckedList(checkedValues.map(String));
                                            }}
                                        />
                                        <Divider>Columns displayed</Divider>
                                        <div style={{ marginBottom: 16 }}>
                                            <span>Change Table Cell Size: </span>
                                            <Radio.Group
                                                value={tableSize}
                                                onChange={(e) => setTableSize(e.target.value)}
                                            >
                                                <Radio.Button value="large">Large</Radio.Button>
                                                <Radio.Button value="middle">Middle</Radio.Button>
                                                <Radio.Button value="small">Small</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </Modal>
                                </div>
                                <Table
                                    style={{
                                        minWidth: '100%',
                                        paddingBottom: '16px',
                                    }}
                                    className={'casino_performance-table'}
                                    pagination={{
                                        pageSize,
                                        position: ['topRight'],
                                        pageSizeOptions: [10, 20, 50, 100],
                                        onShowSizeChange: handlePageSizeChange,
                                    }}
                                    scroll={{ x: totalWidth, y: tableHeight }}
                                    dataSource={usersWithActions}
                                    columns={visibleColumns}
                                    rowKey={(record) => `${record.email.toString()}`}
                                    footer={() => <></>}
                                    size={tableSize}
                                    rowClassName={(record) => record.blocked && 'blocked'}
                                />
                                {isUserStatusChanging() && <ChangeUserStatusLoader />}
                            </div>
                        ) : (
                            <Empty />
                        )
                    ) : (
                        <Loader style={{ height: '70vh' }} />
                    )
                }
            />
            <AddUserPopupComponent
                visible={state.isAddUserPopupVisible}
                onCancel={handleAddUserCancel}
                onSubmit={handleAddUserSubmit}
                t={t}
            />
            {state.editingUser && (
                <>
                    <ChangeUserNamePopupComponent
                        name={state.editingUser.name}
                        visible={state.isChangeNamePopupVisible}
                        onCancel={handleChangeUserNameCancel}
                        onSubmit={handleChangeUserNameSubmit}
                        t={t}
                    />
                    <ChangeUserRolePopupComponent
                        role={state.editingUser.role}
                        visible={state.isChangeRolePopupVisible}
                        onCancel={handleChangeUserRoleCancel}
                        onSubmit={handleChangeUserRoleSubmit}
                        t={t}
                    />
                    <ChangeUserPasswordPopupComponent
                        editingUser={state.editingUser}
                        visible={state.isChangePasswordPopupVisible}
                        onCancel={handleChangePasswordCancel}
                        onSubmit={handleChangePasswordSubmit}
                        t={t}
                    />
                </>
            )}
        </>
    );
};

export default UsersComponent;
