export const PlayersReportTypes = {
    PLAYERS_REPORT_REQUEST: 'PLAYERS_REPORT_REQUEST',
    PLAYERS_REPORT_SUCCESS: 'PLAYERS_REPORT_SUCCESS',
    PLAYERS_REPORT_FAILED: 'PLAYERS_REPORT_FAILED',
    PLAYERS_REPORT_TOTAL_REQUEST: 'PLAYERS_REPORT_TOTAL_REQUEST',
    PLAYERS_REPORT_TOTAL_SUCCESS: 'PLAYERS_REPORT_TOTAL_SUCCESS',
    PLAYERS_REPORT_FAILED_TOTAL: 'PLAYERS_REPORT_FAILED_TOTAL',
};

export const getPlayersReport = (payload?: any) => ({
    type: PlayersReportTypes.PLAYERS_REPORT_REQUEST,
    payload,
});

export const playersReportPageableSuccess = (payload?: any) => ({
    type: PlayersReportTypes.PLAYERS_REPORT_SUCCESS,
    payload,
});
export const getPlayersReportTotal = (payload?: any) => ({
    type: PlayersReportTypes.PLAYERS_REPORT_TOTAL_REQUEST,
    payload,
});
export const playersReportGrandTotalSuccess = (payload?: any) => ({
    type: PlayersReportTypes.PLAYERS_REPORT_TOTAL_SUCCESS,
    payload,
});
